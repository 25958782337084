export const ERROR_MESSAGES = {
  LICENSE: '＊「お持ちの資格」を少なくとも１つ選択してください',
  EXPERIENCE: '＊「ご経験」を少なくとも１つ選択してください',
  PREFECTURE: '＊「都道府県」を選択してください',
  BIRTHYEAR: '＊「生まれた年」を選択してください',
  NAME: '＊「お名前」を入力してください',
  TEL: '＊10桁か11桁の番号を入力してください',
  EMAIL: '＊正しい「メールアドレス」を入力してください',
  TERMS: '＊利用規約に同意してください',
  AI_CATEGORY: '＊「職種」を選択してください',
  AI_LICENSE: '＊「資格」を少なくとも１つ選択してください',
  AI_PREFECTURE: '＊「勤務地」を選択してください',
  AI_CAREERLENGTH: '＊「経験年数」を選択してください',
  AI_COMPANYNAME: '＊「会社名」を入力してください',
  AI_NAME: '＊「ご担当者名」を入力してください',
};

export const CSRF_TOKEN = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content');
